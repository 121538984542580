import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import seatingManagerChangelog from '@pages/seating-manager/docs/changelog-seating-manager.json5';
import scheduledStockChangelog from '@pages/scheduled-stock/docs/changelog-scheduled-stock.json5';
import rollingStockChangelog from '@pages/rolling-stock/docs/changelog-rolling-stock.json5';
import seatingOverridesChangelog from '@pages/seating-overrides/docs/changelog-seating-overrides.json5';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Changelog = makeShortcode("Changelog");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "seating-changelog"
    }}>{`Seating Changelog`}</h1>
    <Changelog changelogData={[seatingManagerChangelog, scheduledStockChangelog, rollingStockChangelog, seatingOverridesChangelog]} mdxType="Changelog" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      